import React from 'react';
import { IonCard, IonCardContent,IonPage,IonText} from '@ionic/react';


const Login = () => {
    return (
        <IonPage style={{ background: `#e6ffe6` }}>
            <IonCard className="ion-padding" style={{ overflow: `auto` }}>
                <IonCardContent>
                    <u><h1>Login</h1></u>
                    <br />
                    <h2><IonText color="success"><b><u>Free Shipping</u></b></IonText></h2>
                    <p>Paragraph</p>
                    <br />
                    <h2><IonText color="success"><b><u>Free to Edmondson Park</u></b></IonText></h2>
                    <p>Paragraph</p>
                </IonCardContent>
            </IonCard>
        </IonPage>
    );
}

export default Login;