import { IonCol, IonGrid, IonLabel, IonPage, IonRow, IonText } from "@ionic/react";
import React from "react";
import HomeEntity from "../components/HomeEntity";
import { ItemList } from "../data/HomeData";
import "../theme/variables.css";

class Home extends React.Component {
  render() {
    const displayItemsList = () => {
      return ItemList.map((data, key) => {
        return (
          <IonCol size="3" size-sm="6" size-xs="12" size-md="4" key={key}>
            <HomeEntity
              id={data.id}
              image={data.image}
              title={data.title}
              nav={data.nav}
              shotDesc={data.shotDesc}
              price={data.price}
              ship={data.ship}
            />
          </IonCol>
        );
      });
    };

    return (
      <IonPage style={{ background: `#e6ffe6` }}>
        <IonText className="moto ion-padding ion-hide-md-down" color="green">
          We at Littlefarms Australia have embarked on a journey to reconnect
          with nature and we are inviting you to join us in this adventure.
          <br />
          Food that is grown locally in a sustainable way has a low carbon
          footprint and tastes better because it is fresh and nutrient dense.
          {/*<br />
          What could be more local than our own home. So let’s get growing
    and transform your home into a littlefarm.*/}
        </IonText>
        <IonLabel className="whatisnew ion-padding-start">
          What's growing...
        </IonLabel>
        <IonGrid style={{ border: `none`, overflow: `auto`, padding: `1%` }}>
          <IonRow>
            {displayItemsList()}
          </IonRow>
        </IonGrid>
      </IonPage>
    );
  }
}

export default Home;
