import React, { useContext, useEffect } from "react";
import {
  IonApp,
  IonContent,
  IonHeader,
  IonSplitPane,
  IonRouterOutlet,
  IonFooter,
  IonToolbar,
  IonRouterLink,
} from "@ionic/react";
import { Route, Switch } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import TopBar from "./components/TopBar";
import SideMenu from "./components/SideMenu";

import Home from "./page/Home";
import About from "./page/About";
import ShipAndPay from "./page/ShipAndPay";
import Seed from "./page/Seed";
import Poultry from "./page/Poultry";
import Product from "./page/Product";
import Login from "./page/Login";
import ViewCart from "./page/ViewCart";
import SearchResult from "./page/SearchResult";
import { IonReactRouter } from "@ionic/react-router";
import { CartContext, CartContextType, ICartItem } from "./store/CartContext";
import OrderProcess from "./page/OrderProcess";



const App: React.FC = () => {

  const { addToCart } = useContext(CartContext) as CartContextType;
  const [screenSize, setScreenSize] = React.useState<string>(
    window.innerWidth >= 1281 ? "1280px" : "100%");

  useEffect(() => {
    const cartData = localStorage.getItem("cart")
    if (cartData) {
      const cartArray = JSON.parse(cartData) || []
      if (Array.isArray(cartArray)) {
        var i
        for (i = 0; i < cartArray.length; i++) {
          const newItem: ICartItem = {
            id: cartArray[i].id, quantity: cartArray[i].quantity,
            title: cartArray[i].title,
            image: cartArray[i].image,
            price: cartArray[i].price,
            content: cartArray[i].content,
            ship: cartArray[i].ship
          }
          addToCart(newItem)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    function setupListener() {
      function handleResize() {
        setScreenSize(window.innerWidth >= 1281 ? "1280px" : "100%")
      }
      window.addEventListener("resize", handleResize)

      return function cleanupListener() {
        window.removeEventListener("resize", handleResize)
      }
    }
  )


  return (
    <IonApp style={{ background: `#C1E1C1` }}>
      <IonHeader className="ion-no-border">
        <TopBar></TopBar>
      </IonHeader>
      <IonContent style={{ width: `${screenSize}` }} class="ion-align-self-center">
        <IonSplitPane contentId="main">
          <SideMenu></SideMenu>
          <IonReactRouter>
            <IonRouterOutlet id="main">
              <Switch>
                <Route exact={true} path="/about" component={About} />
                <Route exact={true} path="/shipandpay" component={ShipAndPay} />
                <Route exact={true} path="/orderprocess" component={OrderProcess} />
                <Route exact={true} path="/seed" component={Seed} />
                <Route exact={true} path="/poultry" component={Poultry} />
                <Route exact={true} path="/login" component={Login} />
                <Route exact={true} path="/search/:text" component={SearchResult} />
                <Route exact={true} path="/cart" component={ViewCart} />
                <Route exact={true} path="/Home" component={Home} />
                <Route exact={true} path="/" component={Home} />
                <Route exact={true} path="/Product/:id" component={Product} />
              </Switch>
            </IonRouterOutlet>
          </IonReactRouter>
        </IonSplitPane>
      </IonContent>
      <IonFooter className="ion-hide-md-down">
        <IonToolbar><i> &nbsp;For B2B please <strong><IonRouterLink routerLink="/about">contact us</IonRouterLink></strong> via email.</i></IonToolbar>
      </IonFooter>
    </IonApp>
  );
}

export default App;
