import React from "react";
import {
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonImg,
  IonChip,
  IonLabel,
  IonRouterLink,
} from "@ionic/react";
import { cartOutline } from "ionicons/icons";
import Search from "./Search";
import { CartContext } from "../store/CartContext";

interface TopBarProps { }

interface TopBarState {
  logowidth: number;
}

class TopBar extends React.Component<TopBarProps, TopBarState> {
  static contextType = CartContext;

  constructor(props: TopBarProps) {
    super(props);
    this.state = {
      logowidth:
        window.innerWidth >= 992 ? 25 : window.innerWidth <= 576 ? 80 : 50,
    };
  }

  handleResize = () => {
    const windowWidth = window.innerWidth;
    this.setState({
      logowidth: windowWidth >= 992 ? 25 : window.innerWidth <= 576 ? 80 : 50,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const searchBox = window.innerWidth > 768 ? <Search /> : "";
    const showMenuButton = () => {
      if (window.innerWidth < 992) {
        return (
          <IonCol className="ion-align-self-center">
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton color="dark"></IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonCol>
        );
      }
      return "";
    };

    return (
      <IonGrid style={{ background: `#FFFFFF` }}>
        <IonRow className="ion-align-items-end">
          {showMenuButton()}
          <IonCol className="ion-align-self-center" offset-md="1" offset-lg="0">
            <IonRouterLink routerLink="/Home">
              <IonImg
                src="assets/icon/BaseLogo.png"
                alt="Littlefarms"
                style={{ display: `block`, width: `${this.state.logowidth}%` }}
              /></IonRouterLink>
          </IonCol>
          {searchBox !== "" ? (
            <IonCol className="ion-align-self-center">{searchBox}</IonCol>
          ) : (
            ""
          )}
          <IonCol className="ion-align-self-center ion-text-right">
            {/*<IonRouterLink routerLink="/login">
            <IonChip outline>
              <IonLabel>Login</IonLabel>
              <IonIcon icon={personCircleOutline}></IonIcon>
            </IonChip>
          </IonRouterLink>*/}
            <IonRouterLink routerLink="/cart">
              <IonChip outline>
                <IonIcon icon={cartOutline}></IonIcon>
                <IonLabel color="success"><b>{this.context.cart.length}</b></IonLabel>
              </IonChip>
            </IonRouterLink>
          </IonCol>
        </IonRow>
        {searchBox === "" ? (
          <IonRow>
            <Search />
          </IonRow>
        ) : (
          ""
        )}
      </IonGrid>
    );
  }
}

export default TopBar;
