import React, { useState, useContext } from 'react';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonInput, IonItem, IonLabel, IonList, IonPage, IonRadio, IonRadioGroup, IonRow, IonSelect, IonSelectOption, useIonAlert } from '@ionic/react';
import { CartContext, CartContextType } from "../store/CartContext";
import PayOther from "../components/PayOther";

import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser'


interface OrderProcessProps {
}

const OrderProcess = (props: OrderProcessProps) => {
    const RECAPTCHA_KEY = '6LfUfh8gAAAAAD4yPoUI2dZcgwqmGGjEnffd4wPP'
    const recaptchaRef = React.useRef(null);
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const { cart, clearCart } = useContext(CartContext) as CartContextType;
    const [dateTimeSize, setDateTimeSize] = React.useState<number>(
        window.innerWidth >= 992 ? 50 : 100
    );
    const [capthaSize, setCapthaSize] = React.useState<string>(
        window.innerWidth >= 992 ? "normal" : "compact"
    );
    const [mail, setMail] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [name, setName] = useState<string>();
    const [add1, setAdd1] = useState<string>();
    const [add2, setAdd2] = useState<string>();
    const [postCode, setPostCode] = useState<string>();
    const [territory, setTerritory] = useState<string>();
    /*const [payOption, setPayOption] = useState<string>("paycard");*/
    const [cod, setCOD] = useState<boolean>(false);
    const [disableCaptha, setDisableCaptha] = React.useState<boolean>(true);
    const [present] = useIonAlert();
    const [mon, setMon] = useState<string>();

    React.useEffect(
        function setupListener() {
            function handleResize() {
                setDateTimeSize(window.innerWidth >= 992 ? 50 : 100)
                setCapthaSize(window.innerWidth >= 992 ? "normal" : "compact")
            }
            window.addEventListener("resize", handleResize)

            return function cleanupListener() {
                window.removeEventListener("resize", handleResize)
            }
        }
    )

    const shippingDetails = () => {
        return (<IonCard style={{ width: `${dateTimeSize}%` }}>
            <IonCardHeader><IonCardTitle>Shipping Details</IonCardTitle></IonCardHeader>
            <IonInput style={{ margin: "5px 5px", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: "97%", padding: "10px 20px" }} value={name} placeholder="Full Name" type="text" onIonChange={e => setName(e.detail.value!)}></IonInput>
            <IonInput style={{ margin: "5px 5px", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: "97%", padding: "10px 20px" }} value={add1} placeholder="Address Line 1" type="text" onIonChange={e => setAdd1(e.detail.value!)}></IonInput>
            <IonInput style={{ margin: "5px 5px", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: "97%", padding: "10px 20px" }} value={add2} placeholder="Address Line 2" type="text" onIonChange={e => setAdd2(e.detail.value!)}></IonInput>
            <IonRow>
                <IonInput style={{ margin: "5px 5px", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", padding: "10px 20px" }} value={postCode} placeholder="Post Code" type="text" onIonChange={e => { setPostCode(e.detail.value!); }}></IonInput>
                <IonSelect interface="popover" style={{ margin: "5px 5px", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: "45%", padding: "10px 20px" }} value={territory} placeholder="State/Territory" onIonChange={e => setTerritory(e.detail.value!)}>
                    <IonSelectOption value="NSW">NSW</IonSelectOption>
                    <IonSelectOption value="VIC">VIC</IonSelectOption>
                    <IonSelectOption value="QLD">QLD</IonSelectOption>
                    <IonSelectOption value="SA">SA</IonSelectOption>
                    <IonSelectOption value="NT">NT</IonSelectOption>
                </IonSelect>
                &nbsp;&nbsp;
            </IonRow>
        </IonCard>);
    }

    const showCaptha = () => {
        if (capthaSize === "normal") {
            return (<ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="normal"
                id="recaptcha-google"
                onChange={(e) => { setDisableCaptha(false) }} />)
        }
        else {
            return (<ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="compact"
                id="recaptcha-google"
                onChange={(e) => { setDisableCaptha(false) }} />)
        }
    }

    const showTotal = () => {
        if (cart.length !== 0) {
            var i;
            var totalShop = 0;
            for (i = 0; i < cart.length; ++i) {
                totalShop = totalShop + (cart[i].quantity * parseFloat(cart[i].price))
            }
            return (<IonLabel color="dark" style={{ fontSize: "1.5em" }}>Total &nbsp;&nbsp;&nbsp; ${totalShop.toFixed(2)}</IonLabel>);
        }
        else {
            return null;
        }
    }

    const showNameBox = () => {
        return (<IonInput style={{ margin: "2px 10px", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: `${dateTimeSize}%`, padding: "12px 20px" }} value={name} placeholder="Name" type="text" autocomplete="name" onIonChange={e => setName(e.detail.value!)}></IonInput>)
    }

    const showEmailBox = () => {
        return (<IonInput style={{ margin: "2px 10px", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: `${dateTimeSize}%`, padding: "12px 20px" }} value={mail} placeholder="Email" type="email" autocomplete="email" onIonChange={e => { setMail(e.detail.value!); }}></IonInput>)
    }

    const showPhoneBox = () => {
        return (<IonInput style={{ margin: "2px 10px", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: `${dateTimeSize}%`, padding: "12px 20px" }} value={phone} placeholder="Mobile (optional)" type="tel" onIonChange={e => setPhone(e.detail.value!)}></IonInput>)
    }

    const showPickupTimeOptions = () => {
        var today = new Date();
        today.setDate(today.getDate() + ((7 - today.getDay()) % 7 + 1) % 7);
        var mon1 = "Monday " + today.getDate() + " " + monthNames[today.getMonth()] + " , 7:00 am to 7:00 pm"
        today.setDate(today.getDate() + 7)
        var mon2 = "Monday " + today.getDate() + " " + monthNames[today.getMonth()] + " , 7:00 am to 7:00 pm"
        today.setDate(today.getDate() + 7)
        var mon3 = "Monday " + today.getDate() + " " + monthNames[today.getMonth()] + " , 7:00 am to 7:00 pm"
        return (
            <IonCard style={{ width: `${dateTimeSize}%` }}>
                <IonCardHeader><IonCardTitle>Please Select A Date And Time For Pickup</IonCardTitle></IonCardHeader>
                <IonList lines='full'>
                    <IonRadioGroup value={mon} onIonChange={e => setMon(e.detail.value!)} >
                        <IonItem>
                            <IonLabel>{mon1}</IonLabel>
                            <IonRadio slot="start" value={mon1} />
                        </IonItem>

                        <IonItem>
                            <IonLabel>{mon2}</IonLabel>
                            <IonRadio slot="start" value={mon2} />
                        </IonItem>

                        <IonItem>
                            <IonLabel>{mon3}</IonLabel>
                            <IonRadio slot="start" value={mon3} />
                        </IonItem>
                    </IonRadioGroup>
                </IonList>
            </IonCard>
        )
    }

    const showShippingDetails = () => {
        if (cart.length !== 0) {
            var freeShip = true;
            var i;
            for (i = 0; i < cart.length; ++i) {
                if (cart[i].ship === "Ed") {
                    return (<p><IonLabel color="dark">Shipping Method</IonLabel><br /> <b><u>Pickup from</u></b> <br /><p style={{ "color": "blue" }}> <i>35 Sammarah Rd <br />Edmondson Park NSW 2174.
                    </i></p><br />{showPickupTimeOptions()} <br /> {showNameBox()} <br /> {showEmailBox()} <br /> {showPhoneBox()}<br />{showPayment()}<br />{showCOD()}<br /><br />{showCaptha()}<br />{showPlaceOrder(false)} </p>);
                }
                else if (cart[i].ship !== "Free") {
                    freeShip = false
                }
            }
            if ({ freeShip }) {
                return (<p>Shipping Method <p style={{ "color": "green" }}><i>Free Shipping</i></p><br />{showEmailBox()} <br /> {showPhoneBox()} <br />{shippingDetails()} <br />{showPayment()}<br /><br />{showCaptha()}<br />{showPlaceOrder(true)} </p>);
            }
            else {
                return (<p>Shipping Method <p style={{ "color": "red" }}><i>Shipping to be calculated</i></p></p>);
            }
        }
        return ("");
    }

    /*const showPaymentBox = () => {
        if (payOption === "payother") {
            return (<PayOther></PayOther>)
        }
        else {
            return (<PayCard></PayCard>)
        }
    }*/

    const showCOD = () => {
        return (
            <>
                <IonLabel color="danger"><b>OR you can pay cash/card on pickup (please tender exact change if payment by cash).</b></IonLabel>
                <br /><br />
                <IonCheckbox color="success" style={{ verticalAlign: "sub" }} onIonChange={e => setCOD(e.detail.value!)} />
                <IonLabel className='ion-padding' style={{ verticalAlign: "top", fontSize: "1.1em" }}><b>Pay Cash/Card On Pickup.</b></IonLabel>
            </>
        );
    }

    const showPayment = () => {
        return (
            <>
                <IonCard style={{ width: `${dateTimeSize}%` }}>
                    <IonCardHeader><IonCardTitle>Payment Option</IonCardTitle></IonCardHeader>
                    <IonCardContent>
                        {/* <IonSegment onIonChange={e => setPayOption(e.detail.value!)} color="success" value={payOption}>
                            <IonSegmentButton value="paycard">
                                <IonLabel>Card / Paypal</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="payother">
                                <IonLabel>Offline</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
        {showPaymentBox()}*/}
                        <PayOther></PayOther>
                    </IonCardContent>
                </IonCard>
            </>
        )
    }

    const handleSubmit = (e: React.FormEvent<HTMLIonButtonElement>) => {
        var today = new Date();
        var orderId = today.getDate().toString() + today.getMonth() + today.getHours() + today.getMinutes() + today.getSeconds() + today.getMilliseconds();
        var msg = 'Your Order ID is <br/><b>' + orderId + '</b>.<br/>';
        if (cod !== false) {
            msg += 'You will receive a confirmation email soon. <br/>Please tender exact change on pickup, <br/>or you can pay by card.'
        }
        else {
            msg += 'You will receive a confirmation email soon with payment option details.'
        }
        var formData = {
            orderId: orderId,
            email: mail,
            pick: mon,
            orderDetails: JSON.stringify(cart),
            phone: phone,
            name: name,
            add1: add1,
            add2: add2,
            postCode: postCode,
            territory: territory,
            cod: cod
        };
        e.preventDefault();
        emailjs.send('littlefarms', 'template_jy8pz86', formData, 'cWkMzJ-XzIcsHtsmV')
            .then((result) => present({
                header: 'Thankyou for your order',
                message: msg,
                buttons: [{ text: 'Close' }],
                onDidDismiss: () => { clearCart(); localStorage.setItem("cart", JSON.stringify(cart)); window.location.replace("/home"); }
            }), (error) => { alert("Sorry order not processed.\nPlease contact directly on +61-434153151.\nOR\nOver email littlefarms.australia@gmail.com") });
    }

    const showPlaceOrder = (disableButton: boolean) => {
        if (cart.length !== 0) {
            return (<IonButton color="success" routerLink='/home' disabled={getDisablePlaceOrderButton(disableButton)} onClick={e => { handleSubmit(e) }}>Place Order</IonButton>);
        }
        else {
            return ("");
        }
    }

    const getDisablePlaceOrderButton = (disableButton: boolean) => {
        if (disableButton === true) {
            if (
                (mail === undefined) ||
                (name === undefined) ||
                (add1 === undefined) ||
                (postCode === undefined) ||
                (disableCaptha)
            ) {
                return true;
            }
        }
        else {
            if (
                (mail === undefined) ||
                (disableCaptha)
            ) {
                return true;
            }
        }
        return false;
    }

    return (
        <IonPage style={{ background: `#e6ffe6` }}>
            <IonCard className="ion-padding" style={{ overflow: `auto` }}>
                <IonCardHeader><IonCardTitle><IonLabel style={{ 'border-bottom': 'solid', 'font-size': '120%' }}> Checkout Order &nbsp;</IonLabel></IonCardTitle></IonCardHeader>
                <IonCardContent>
                    <IonButton color="warning" routerLink='/cart'>&lt;&lt; Review Cart</IonButton>
                    <br />
                    {showTotal()}
                    <br />
                    {showShippingDetails()}
                    <br />
                </IonCardContent>
            </IonCard>
        </IonPage>
    );
}

export default OrderProcess;