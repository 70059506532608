import React, { useState } from "react"
import { IonSearchbar } from "@ionic/react";

const Search = () => {
    const [searchText, setSearchText] = useState<string>('');

    React.useEffect(() => {
        if (searchText !== '')
        {
            window.location.replace("/search/" + searchText);
        } 
        
     }, [searchText])

    return (
        <IonSearchbar value={searchText} debounce={2000} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
    );
};

export default Search;