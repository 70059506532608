import React from 'react';
import { IonCol, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import { useParams } from 'react-router';
import { ItemList } from "../data/HomeData";
import HomeEntity from "../components/HomeEntity";


const SearchResult = () => {
    const { text } = useParams<{ text: string }>();

    const displayItemsList = () => {
        let searchText = text.toLowerCase().split(' ');
        if (searchText[0].includes('ship') ||
            searchText[0].includes('pay') ||
            searchText[0].includes('deliv')) {
            window.location.replace("/shipandpay");
        }
        else if (searchText[0].includes("conta") ||
            searchText[0].includes("about") ||
            searchText[0].includes("email") ||
            searchText[0].includes('mobile') ||
            searchText[0].includes('phone') ||
            searchText[0].includes('message') ||
            searchText[0].includes('little')) {
            window.location.replace("/about");
        }
        else if (searchText[0].includes("poult"))
        {
            window.location.replace("/poultry");
        }
        else if (searchText[0].includes("fresh") ||
        searchText[0].includes("farm")||
        searchText[0].includes("produce"))
        {
            window.location.replace("/home");
        }

        var tempSearchResult = ItemList.filter(ele => ele.title.toLowerCase().includes(searchText[0]));
        if (tempSearchResult.length !== 0) {
            return tempSearchResult.map((data, key) => {
                return (

                    <IonCol size="3" size-sm="6" size-xs="12" size-md="4" key={key}>
                        <HomeEntity
                            id={data.id}
                            image={data.image}
                            title={data.title}
                            nav={data.nav}
                            shotDesc={data.shotDesc}
                            price={data.price}
                            ship={data.ship}
                        />
                    </IonCol>
                );
            });
        }
        return (
            <h2><IonText color="danger">No result found.</IonText></h2>
        );
    }
    return (
        <IonPage style={{ background: `#e6ffe6` }}>
            <h1>&nbsp;<u><IonText color="medium">Search for {text}</IonText></u></h1>
            <br/>
            <IonGrid style={{ border: `none`, overflow: `auto`, padding: `1%` }}>
                <IonRow>
                    {displayItemsList()}
                </IonRow>
            </IonGrid>
        </IonPage>
    );
}

export default SearchResult;