export const ItemList = [
    {
        id: 100,
        title: "Bottle Gourd Seed",
        image: "assets/Lau_lf_3000_3000.jpg",
        nav: [
            { name: "Seed", value: "/seed" }
        ],
        price: "$4.00",
        ship: "Free",
        shotDesc: "5 Organically grown seeds",
    },
    {
        id: 101,
        title: "Hyacinth Bean, Lablab Seeds",
        image: "assets/HBean_lf_3000_3000.jpg",
        nav: [
            { name: "Seed", value: "/seed" }
        ],
        price: "$4.00",
        ship: "Free",
        shotDesc: "5 Organically grown seeds",
      }
];