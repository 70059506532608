import React from "react";
import {
  IonMenu,
  IonContent,
  IonItem,
  IonLabel,
} from "@ionic/react";
import "../theme/variables.css";

interface SideMenuProps {
}

interface SideMenuState {
  setMenuWidth: boolean;
  menuItemColor: string;
}

class SideMenu extends React.Component<SideMenuProps, SideMenuState> {
  constructor(props: SideMenuProps) {
    super(props);
    this.state = {
      setMenuWidth: window.innerWidth >= 900 ? true : false,
      menuItemColor:"",
    };
  }

  handleResize = () => {
    this.setState({
      setMenuWidth: window.innerWidth >= 900 ? true : false,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const menuStyle = () => {
      if (this.state.setMenuWidth) {
        return { display: "block", width: "22%" };
      }
      return {};
    };

    return (
      <IonMenu side="start" contentId="main" style={menuStyle()}>
        <IonContent>
          <IonItem lines="none" button={true} routerLink="/Home">
            <IonLabel>Home</IonLabel>
          </IonItem>
{/*          <IonItem lines="none" routerLink='/seed' button= {true} >
            Seeds
    </IonItem>*/}
          <IonItem lines="none" routerLink='/poultry' button= {true} >
            Poultry
          </IonItem>
{/*          <IonItem lines="none" routerLink="/">
            Farm Fresh Produce
          </IonItem>
          <IonItem lines="none" routerLink="/about">
            Other Items
          </IonItem>*/}
          <IonItem lines="none" routerLink="/shipandpay">
            Shipping & Payment
          </IonItem>
          <IonItem lines="none" routerLink="/about">
            Contact Us
          </IonItem>
        </IonContent>
      </IonMenu>
    );
  }
}

export default SideMenu;
