export const ItemList = [
  {
    id: 1,
    title: "Quail Eggs",
    image: "assets/Qegg_lf_3000_3000.jpg",
    nav: [
      { name: "Poultry", value: "/poultry" }
    ],
    price: "$6.00",
    ship: "Ed",
    shotDesc: "18 Pasture Raised Quail eggs",
  },
  {
    id: 2,
    title: "Fertile Coturnix Quail Eggs",
    image: "assets/Quail_lf_3000_3000.jpg",
    nav: [
      { name: "Poultry", value: "/poultry" }
    ],
    price: "$8.00",
    ship: "Ed",
    shotDesc: "12 Fertile Quail eggs",
  },
  {
    id: 3,
    title: "Quail Egg Scissors",
    image: "assets/Qscissor_lf_3000_3000.jpg",
    nav: [
      { "name": "Poultry", "value": "/poultry" }
    ],
    price: "$5.00",
    ship: "Ed",
    shotDesc: "1 Quail Egg Scissors",
  },
  {
    id: 4,
    title: "Farm Fresh Chicken Eggs",
    image: "assets/Cegg_lf_3000_3000.jpg",
    nav: [
      { name: "Poultry", value: "/poultry" }
    ],
    price: "$6.00",
    ship: "Ed",
    shotDesc: "12 Farm Fresh eggs",
  },
  {
    id: 5,
    title: "Fertile Chicken Eggs (Mixed)",
    image: "assets/FEgg_lf_3000_3000.jpg",
    nav: [
      { name: "Poultry", value: "/poultry" }
    ],
    price: "$24.00",
    ship: "Ed",
    shotDesc: "12 Fertile eggs",
  },
  {
    id: 6,
    title: "Pure Australorp Fertile Eggs",
    image: "assets/FAstro_lf_3000_3000.jpg",
    nav: [
      { name: "Poultry", value: "/poultry" }
    ],
    price: "$24.00",
    ship: "Ed",
    shotDesc: "12 Fertile eggs",
  }
];
