import React from 'react';
import { IonRow, IonText } from '@ionic/react';


const PayOther = () => {
    return (
        <>
            <br />
            <IonRow><h2><b>Bank Pay ID Email :</b> &nbsp;littlefarms.australia@gmail.com</h2></IonRow>
            <IonRow><h2><b>Bank Pay ID Mobile :</b> &nbsp;0434153151</h2></IonRow>
            <IonRow><h2><b>Bank Transfer :</b> <br /> &nbsp;&nbsp;&nbsp;<IonText color="primary">BSB : </IonText>062247<br />&nbsp;&nbsp;&nbsp;<IonText color="primary">Account : </IonText>10273583</h2></IonRow>
            <IonRow><h2><b>Paypal Link :</b> <br /> &nbsp;&nbsp;&nbsp;<IonText color="primary"><a href='https://paypal.me/littlefarmsAu' target="_blank" rel="noopener noreferrer">https://paypal.me/littlefarmsAu</a></IonText></h2></IonRow>
            <br /><p>Please place order first and wait for the order confirmation email before making payment. You will receive all the payment details in the confirmation email. We will send you the unique order id which you can use in  future communications  regarding the order. <br /><b>Please use the order id or your name as referrence when making the payment.</b> </p>
        </>
    );
}

export default PayOther;