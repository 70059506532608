import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonRouterLink,
  IonText,
} from "@ionic/react";

interface NavigationLink {
  name: string;
  value: string;
}

interface HomeEntityProps {
  id: number;
  image: string;
  title: string;
  nav?: NavigationLink[];
  shotDesc?: string;
  price?: string;
  ship?: string;
}

interface HomeEntityState { }

class HomeEntity extends React.Component<HomeEntityProps, HomeEntityState> {
  render() {
    const showNavigations = () => {
      if (this.props.nav) {
        let navLinks: any = [];
        for (let i in this.props.nav) {
          navLinks.push(
            <span key={i}>
              {i === "0" ? null : " > "}
              <IonRouterLink
                routerLink={this.props.nav[i].value}
              >
                {this.props.nav[i].name}
              </IonRouterLink>
            </span>
          );
        }

        return <IonCardSubtitle>{navLinks}</IonCardSubtitle>;
      }
      return <br />;
    };

    const showShipping = () => {
      if (this.props.ship) {
        if (this.props.ship === "Free") {
          return (<IonText color="success">&nbsp;&nbsp;&nbsp;Free Shipping</IonText>);
        }
        else if (this.props.ship === "Ed") {
          return (<IonText color="tertiary">&nbsp;&nbsp;&nbsp;Pickup Edmondson Park</IonText>);
        }
        else {
          return (<IonText color="warning">&nbsp;&nbsp;&nbsp;Shipping : {this.props.ship}</IonText>);
        }
      }
      return ("");
    };

    /*const showAddToCart = () => {
      if (this.props.price) {
        return (<IonButton color="medium" shape="round" size="small" fill="outline" className="addtocart" onClick={() => alert()}>ADD TO CART</IonButton>);
      }
    }*/

    return (
      <>
        <IonCard>
          <IonRouterLink routerLink={"/Product/" + this.props.id}>
            <img
              width="100%"
              height="100%"
              src={this.props.image}
              alt={this.props.title}
            />
          </IonRouterLink>
          <IonCardHeader>
            {showNavigations()}
            <IonCardTitle>
              <IonRouterLink
                color="dark"
                routerLink={"/Product/" + this.props.id}
              >
                {this.props.title}
              </IonRouterLink>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonRouterLink
              color="medium"
              routerLink={"/Product/" + this.props.id}
            >
              <h2>
                <b>{this.props.price} </b>
                {showShipping()}</h2>
              {this.props.shotDesc ? (<><br /><h2>{this.props.shotDesc}</h2><br /></>) : ("")}
              {this.props.children}
            </IonRouterLink>
          </IonCardContent>
        </IonCard>
      </>
    );
  }
}

export default HomeEntity;
