import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonPage, IonRow, useIonAlert } from '@ionic/react';
import { logoFacebook, logoInstagram, mail } from "ionicons/icons";

import ReCAPTCHA from "react-google-recaptcha";

const About = () => {
    const RECAPTCHA_KEY = '6LfUfh8gAAAAAD4yPoUI2dZcgwqmGGjEnffd4wPP'
    const recaptchaRef = React.useRef(null);
    const [name, setName] = React.useState<string>();
    const [email, setEmail] = React.useState<string>();
    const [message, setMessage] = React.useState<string>();
    const [b2bMsg, setB2BMsg] = React.useState<string>(
        window.innerWidth >= 992 ? "" : "For B2B please contact us via email."
    );
    const [capthaSize, setCapthaSize] = React.useState<string>(
        window.innerWidth >= 992 ? "normal" : "compact"
    );
    const [present] = useIonAlert();
    const [disableSubmitName, setDisableSubmitName] = React.useState<boolean>(true);
    const [disableSubmitEmail, setDisableSubmitEmail] = React.useState<boolean>(true);
    const [disableSubmitMessage, setDisableSubmitMessage] = React.useState<boolean>(true);
    const [disableCaptha, setDisableCaptha] = React.useState<boolean>(true);

    React.useEffect(
        function setupListener() {
            function handleResize() {
                setB2BMsg(window.innerWidth >= 992 ? "" : "For B2B please contact us via email.")
                setCapthaSize(window.innerWidth >= 992 ? "normal" : "compact")
            }
            window.addEventListener("resize", handleResize)

            return function cleanupListener() {
                window.removeEventListener("resize", handleResize)
            }
        }
    )

    const showCaptha = () => {
        if(capthaSize === "normal")
        {
            return (<ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="normal"
                id="recaptcha-google"
                onChange={(e) => {setDisableCaptha(false)}}/>)
        }
        else
        {
            return (<ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="compact"
                id="recaptcha-google"
                onChange={(e) => {setDisableCaptha(false)}}/>)
        }
    }

    const showB2BMsg = () => {
        if (b2bMsg === "") {
            return ""
        }
        else {
            return (<IonLabel color="primary"><i><strong>{b2bMsg}</strong></i></IonLabel>)
        }
    }

    const encode = (data: any) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if(recaptchaRef && recaptchaRef.current)
        {
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'contact', 'name': name, 'email': email, 'message': message })
        })
            .then(() => present({
                header: 'Thankyou',
                subHeader: 'We have received your message.',
                message: 'We will get back to you as soon as possible.',
                buttons: [{ text: 'Close' }],
                onDidDismiss: () => { window.location.reload(); }
            }))
            .catch(error => alert(error))
        event.preventDefault()
        }
    }

    const getDisableSubmitButton = () => {
        if (!disableSubmitName && !disableSubmitEmail && !disableSubmitMessage && !disableCaptha) {
            return false;
        }
        return true;
    }

    return (
        <IonPage style={{ background: `#e6ffe6` }}>
            <IonCard className="ion-padding" style={{ overflow: `auto` }}>
                <IonImg
                    src="assets/icon/BaseColarge.jpg"
                    alt="Littlefarms"
                    style={{ display: `block`, width: `30%`, margin: `auto` }}
                />
                <IonCardContent>
                    <div className="ion-text-center" style={{ fontSize: `1.1rem` }}>
                        Littlefarms is a small aspiring farm in Dalton , Rural NSW.<br />
                        On 20 acres we have started to develop a food forest and raise poultry. Our objective is to grow our food sustainably and encourage others to source local sustainably grown food.
                        Food that is grown locally in a sustainable way has a low carbon footprint and tastes better because it is fresh and nutrient dense.
                    </div>
                    <IonGrid style={{ border: `none`, overflow: `auto`, padding: `1%` }}>
                        <IonRow>
                            <IonCol size="6" size-sm="12" size-xs="12" size-md="6">
                                <IonCard>
                                    <IonCardHeader><IonCardTitle className="ion-text-center">Contact Information</IonCardTitle></IonCardHeader>
                                    <IonCardContent>
                                        <IonItem
                                            style={{ borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em" }}
                                            href="mailto:littlefarms.australia@gmail.com">
                                            <IonIcon icon={mail}></IonIcon> <IonLabel> littlefarms.australia@gmail.com</IonLabel>
                                        </IonItem>
                                        <IonItem
                                            style={{ borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em" }}
                                            href="https://www.facebook.com/LittlefarmsAustralia">
                                            <IonIcon icon={logoFacebook}></IonIcon> <IonLabel> Littlefarms AU</IonLabel>
                                        </IonItem>
                                        <IonItem
                                            style={{ borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em" }}
                                            href="https://www.instagram.com/littlefarms.australia/">
                                            <IonIcon icon={logoInstagram}></IonIcon> <IonLabel> Littlefarms AU</IonLabel>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="6" size-sm="12" size-xs="12" size-md="6">
                                <IonCard>
                                    <IonCardHeader><IonCardTitle className="ion-text-center">Send us a message</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <form name="contact" method="post" data-netlify="true" data-netlify-recaptcha="true"
                                            onSubmit={e => { handleSubmit(e) }}>
                                            <input type="hidden" name="form-name" value="Contact" />
                                            <input style={{ margin: "8px 0", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: "100%", padding: "12px 20px" }} type="text" name="name" placeholder="Name"
                                                value={name} onChange={(e) => {
                                                    setName(e.target.value);
                                                    setDisableSubmitName(false)
                                                }} />
                                            <input style={{ margin: "8px 0", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: "100%", padding: "12px 20px" }} type="email" name="email" placeholder="Email"
                                                value={email} onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    setDisableSubmitEmail(false)
                                                }} />
                                            <textarea style={{ margin: "8px 0", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: "100%", padding: "12px 20px" }} placeholder="Message" name="message"
                                                value={message} onChange={(e) => {
                                                    setMessage(e.target.value);
                                                    setDisableSubmitMessage(false)
                                                }}></textarea>
                                            {showCaptha()}
                                            <button type="submit" style={{ margin: "8px 0", borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: "100%", padding: "12px 20px" }} disabled={getDisableSubmitButton()}>Submit</button>
                                        </form>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {showB2BMsg()}
                </IonCardContent>
            </IonCard>
        </IonPage>
    );
}

export default About;