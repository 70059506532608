import React from 'react';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonPage, IonRouterLink, IonRow } from '@ionic/react';
import { addCircleOutline, removeCircleOutline, trash } from 'ionicons/icons';
import { CartContext, CartContextType } from "../store/CartContext";

const ViewCart = () => {
    const { cart, updateCart } = React.useContext(CartContext) as CartContextType;
    const [imgSize, setImgSize] = React.useState<number>(
        window.innerWidth >= 992 ? 100 : 0
    );

    React.useEffect(
        function setupListener() {
            function handleResize() {
                setImgSize(window.innerWidth >= 992 ? 100 : 0)
            }
            window.addEventListener("resize", handleResize)

            return function cleanupListener() {
                window.removeEventListener("resize", handleResize)
            }
        }
    )

    const showShipping = (ship: string) => {
        if (ship === "Free") {
            return (<p style={{ "color": "green" }}>Free Shipping</p>);
        }
        else if (ship === "Ed") {
            return (<p style={{ "color": "blue" }}>Pickup Edmondson Park</p>);
        }
        else {
            return (<p style={{ "color": "yellow" }}>Shipping : {ship}</p>);
        }
    }

    const togglePlus = (quantity: number) => {
        if (quantity > 2) {
            return true;
        }
        return false;
    }

    const toggleMinus = (quantity: number) => {
        if (quantity < 2) {
            return true;
        }
        return false;
    }

    const showQuantityAndTotalInline = (id: string, image: string, title: string, content: string, price: string, quantity: number, ship: string) => {
        return (
            <IonCol className="ion-align-self-center">
                <IonButton
                    size="small"
                    fill="clear"
                    shape="round"
                    color="medium"
                    className="plusminus"
                    disabled={togglePlus(quantity)}
                    onClick={() => {
                        updateCart({ id: id, quantity: quantity + 1, title: title, image: image, price: price, content: content, ship: ship })
                        localStorage.setItem("cart", JSON.stringify(cart))
                    }}
                >
                    <IonIcon icon={addCircleOutline} />
                </IonButton>
                <strong>{quantity}</strong>
                <IonButton
                    size="small"
                    fill="clear"
                    shape="round"
                    color="medium"
                    className="plusminus"
                    disabled={toggleMinus(quantity)}
                    onClick={() => {
                        updateCart({ id: id, quantity: quantity - 1, title: title, image: image, price: price, content: content, ship: ship })
                        localStorage.setItem("cart", JSON.stringify(cart))
                    }}
                >
                    <IonIcon icon={removeCircleOutline} />
                </IonButton><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Subtotal</strong>
                &nbsp;&nbsp;${(quantity * parseFloat(price)).toFixed(2)}
            </IonCol>
        )
    }

    const showRemove = (id: string, image: string, title: string, content: string, price: string, quantity: number, ship: string) => {
        return (<IonCol className="ion-align-self-center">
            <IonButton
                size="small"
                fill="clear"
                shape="round"
                color="medium"
                className="plusminus"
                onClick={() => {
                    updateCart({ id: id, quantity: 0, title: title, image: image, price: price, content: content, ship: ship })
                    localStorage.setItem("cart", JSON.stringify(cart))
                }}
            >
                <IonIcon size="small" icon={trash} />
            </IonButton>
        </IonCol>)
    }

    const cartItemDisplay = (id: string, image: string, title: string, content: string, price: string, quantity: number, ship: string) => {
        if (imgSize === 100) {
            return (
                <IonRow style={{ 'border-bottom': 'ridge' }}>
                    <IonCol className="ion-align-self-center" size="1">
                        <IonRouterLink routerLink={"/Product/" + id}>
                            <IonImg
                                src={image}
                                style={{
                                    display: `block`,
                                    width: `${imgSize}%`
                                }}
                            /></IonRouterLink>
                    </IonCol>
                    <IonCol className="ion-align-self-start">
                        <IonLabel>
                            <IonRouterLink routerLink={"/Product/" + id}>
                                <h2>{title}</h2></IonRouterLink>
                            <p>{content}</p>
                            <h3>${price}</h3>
                            {showShipping(ship)}
                        </IonLabel>
                    </IonCol>
                    {showQuantityAndTotalInline(id, image, title, content, price, quantity, ship)}
                    {showRemove(id, image, title, content, price, 0, ship)}
                </IonRow>
            )
        }
        else {
            return (
                <IonGrid>
                    <IonRow>
                        <IonCol size="5">
                            <IonRouterLink routerLink={"/Product/" + id}>
                                <IonImg
                                    src={image}
                                    style={{
                                        display: `block`,
                                        width: '100%'
                                    }}
                                /></IonRouterLink>
                        </IonCol>
                        {showQuantityAndTotalInline(id, image, title, content, price, quantity, ship)}
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-align-self-start" size="9">
                            <IonLabel>
                                <IonRouterLink routerLink={"/Product/" + id}>
                                    <h2>{title}</h2></IonRouterLink>
                                <p>{content}</p>
                                <h3>${price}</h3>
                                {showShipping(ship)}
                            </IonLabel>
                        </IonCol>
                        {showRemove(id, image, title, content, price, 0, ship)}
                    </IonRow>
                </IonGrid>
            )
        }
    }

    const showTotal = () => {
        if (cart.length !== 0) {
            var i;
            var totalShop = 0;
            for (i = 0; i < cart.length; ++i) {
                totalShop = totalShop + (cart[i].quantity * parseFloat(cart[i].price))
            }
            return (<IonLabel color="dark" style={{ fontSize: "1.8em" }}>Total &nbsp;&nbsp;&nbsp; ${totalShop.toFixed(2)}</IonLabel>);
        }
        else {
            return null;
        }
    }

    const cartListItems = () => {
        if (cart.length === 0) {
            return (
                <IonRow style={{ 'font-size': '150%' }}>  No Items In Cart</IonRow>
            )
        }
        else {
            return (
                cart.map((data, key) => {
                    return (cartItemDisplay(data.id, data.image, data.title, data.content, data.price, data.quantity, data.ship))
                })
            )
        }
    }

    const showFinalShipping = () => {
        if (cart.length !== 0) 
        {
            var i;
            for (i = 0; i < cart.length; ++i) {
                if(cart[i].ship === "Ed")
                {
                    return (<p><IonLabel color="dark">Shipping Method</IonLabel> <p style={{ "color": "blue" }}><i>Pickup Edmondson Park</i></p></p>);
                }
            }
            return (<p>Shipping Method <p style={{ "color": "green" }}><i>Free Shipping</i></p></p>);
        }
        return ("");
    }

    const showCheckoutButton = () => {
        if (cart.length !== 0) {
            return (<IonButton color="success" routerLink='/orderprocess'>Checkout</IonButton>);
        }
        else{
            return ("");
        }
    }

    return (
        <IonPage style={{ background: `#e6ffe6` }}>
            <IonCard className="ion-padding" style={{ overflow: `auto` }}>
                <IonCardHeader><IonCardTitle><IonLabel style={{ 'border-bottom': 'solid', 'font-size': '120%' }}> Your Cart Items &nbsp;</IonLabel></IonCardTitle></IonCardHeader>
                <IonCardContent >
                    <IonGrid style={{ border: 'none' }}>
                        {cartListItems()}
                    </IonGrid>
                    <IonGrid style={{ border: 'none' }}>
                        <IonRow>
                            <IonCol className="ion-align-self-start" size="12" size-sm sizeMd='5'>{showFinalShipping()}</IonCol>
                            <IonCol className="ion-align-self-center" size="12" size-sm sizeMd='5'>{showTotal()}</IonCol>
                            <IonCol className="ion-align-self-end" size="12" size-sm sizeMd='2'>{showCheckoutButton()}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
        </IonPage>
    );
}

export default ViewCart;