import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React from "react"
import HomeEntity from "../components/HomeEntity";
import { ItemList } from "../data/SeedData";

const Seed: React.FC = () => {

    const displayItemsList = () => {
        return ItemList.map((data, key) => {
          return (
            <IonCol size="3" size-sm="6" size-xs="12" size-md="4" key={key}>
              <HomeEntity
                id={data.id}
                image={data.image}
                title={data.title}
                nav={data.nav}
                shotDesc={data.shotDesc}
                price={data.price}
                ship={data.ship}
              />
            </IonCol>
          );
        });
      };

    return (
        <IonPage style={{ background: `#e6ffe6` }}>
            <IonGrid style={{ border: `none`, overflow: `auto`, padding: `1%` }}>
                <IonRow>
                    {displayItemsList()}
                </IonRow>
            </IonGrid>
        </IonPage>
    );
};

export default Seed;
